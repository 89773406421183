import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const websiteSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'url': publicRuntimeConfig.appBaseUrl,
  };
};
